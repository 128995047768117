import "./style.css";
import { useState, useEffect, useRef } from "react";

function PriceRangeInput({ className, allFilters, setAllFilters }) {
  let [minValue, setMinValue] = useState(0);
  let [maxValue, setMaxValue] = useState(0);

  // let [currentMinValue, setCurrentMinValue] = useState(0);
  // let [currentMaxValue, setCurrentMaxValue] = useState(0);

  const range_1 = useRef();
  const range_2 = useRef();
  // const rangeFill = useRef();

  // Function to validate range and update the fill color on slider
  function validateRange() {
    let minPrice = parseInt(range_1.current.value);
    let maxPrice = parseInt(range_2.current.value);

    if (minPrice > maxPrice) {
      let tempValue = maxPrice;
      maxPrice = minPrice;
      minPrice = tempValue;
    }

    // const minPercentage = ((minPrice - 10) / 490) * 100;
    // const maxPercentage = ((maxPrice - 10) / 490) * 100;

    // rangeFill.current.style.left = minPercentage + "%";
    // rangeFill.current.style.width = maxPercentage - minPercentage + "%";

    setAllFilters((previousState) => {
      return {
        ...previousState,
        priceMin: minPrice,
        priceMax: maxPrice,
      };
    });
    // allFilters.priceMin = minPrice;
    // allFilters.priceMax = maxPrice;
  }

  useEffect(() => {
    fetch("https://time-masters.mavinx.app/api/price")
      .then((response) => response.json())
      .then((obj) => {
        setMinValue(obj.min_price);
        setMaxValue(obj.max_price);
        setAllFilters((previousState) => {
          return {
            ...previousState,
            priceMin: obj.min_price,
            priceMax: obj.max_price,
          };
        });
      });

    // range_1.addEventListener("change", (event) =>
    //   console.log(event.target.value)
    // );
  }, []);

  return (
    <div className={`${className} price-range `}>
      {/* <div className="range-fill" ref={rangeFill}></div> */}

      <input
        type="range"
        className="price-range__input"
        defaultValue={minValue}
        min={minValue}
        max={maxValue}
        step="1"
        onMouseUp={validateRange}
        ref={range_1}
      />
      <input
        type="range"
        className="price-range__input"
        defaultValue={maxValue}
        min={minValue}
        max={maxValue}
        step="1"
        onMouseUp={validateRange}
        ref={range_2}
      />
    </div>
  );
}

export default PriceRangeInput;
