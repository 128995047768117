import "./style.css";
import arrowDown from "../../assets/icons/arrow-down.svg";
import { useState, useEffect, useRef } from "react";

function DropdownMenu({
  className,
  name,
  list,
  text,
  setText,
  setOutput,
  forWhat,
}) {
  const dropdown = useRef();
  const dropdownTextRef = useRef();
  const dropdownListRef = useRef();
  // const [currentValue, setCurrentValue] = useState(text);

  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (!event.target.closest(`.dropdown-menu[name=${name}]`)) {
        dropdownListRef.current.classList.remove("active");
      }
    });
  }, []);

  const toggleDropdownMenu = () => {
    dropdownListRef.current.classList.toggle("active");
  };

  const chooseOptionName = (item) => {
    switch (forWhat) {
      case "service":
        return item.name;
      case "date":
        return item;
      default:
        return null;
    }
  };

  return (
    <button
      className={`${className} dropdown-menu`}
      name={name}
      onClick={toggleDropdownMenu}
      ref={dropdown}
    >
      <div
        className="dropdown-menu__current-value placeholder"
        ref={dropdownTextRef}
      >
        {text}
      </div>
      <img
        className="dropdown-menu__arrow"
        src={arrowDown}
        alt="arrow-down"
      ></img>
      <div className="dropdown-menu__list" ref={dropdownListRef}>
        {list.map((item, index) => (
          <div
            className="dropdown-menu__list-item"
            key={index}
            onClick={() => {
              dropdownTextRef.current.classList.remove("placeholder");

              switch (forWhat) {
                case "service":
                  setOutput(item.id);
                  setText(item.name);
                  break;
                case "date":
                  setOutput(item);
                  setText(item);
                  break;
                default:
                  break;
              }
            }}
          >
            {chooseOptionName(item)}
          </div>
        ))}
      </div>
    </button>
  );
}

export default DropdownMenu;
