import "./css/nullification.css";
import "./css/App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SearchPage from "./pages/SearchPage";
import PersonPage from "./pages/PersonPage";
import Error404Page from "./pages/Error404Page";
import SuccessPage from "./pages/SuccessPage";

function App() {
  const router = createBrowserRouter([
    { path: "/", element: <SearchPage />, errorElement: <Error404Page /> },
    {
      path: "/master-page",
      element: <PersonPage />,
    },
    {
      path: "/error404",
      element: <Error404Page />,
    },
    {
      path: "/success",
      element: <SuccessPage />,
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
