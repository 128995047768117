import "./style.css";
import arrowRight from "../../assets/icons/arrow-right.png";
import upperDecor from "../../assets/decor/upper-decor.png";
import SearchInput from "../../components/SearchInput";
import PersonCard from "../../components/PersonCard";
import FiltersWindow from "../../components/FiltersWindow";
import BigLoupeImage from "../../assets/images/big-loupe.png";
import { useState, useRef, useEffect } from "react";

function SearchPage() {
  const [mayContinue, setMayContinue] = useState(false);
  const [withFilters, setWithFilters] = useState(false);
  const [personsList, setPersonsList] = useState([]);
  const [workByAutocomplete, setworkByAutocomplete] = useState(false);
  const [allFilters, setAllFilters] = useState({
    cityName: null,
    cityToken: null,
    name: null,
    priceMin: null,
    priceMax: null,
    gender: "no_matter",
  });
  const [showFiltersWindow, setShowFiltersWindow] = useState(false);
  const wholePageRef = useRef();
  const headingRef = useRef();
  const subheadingRef = useRef();
  const continueButtonRef = useRef();
  const listRef = useRef();
  // Refresh list with another filters
  const refreshList = () => {
    if (allFilters?.cityToken) {
      console.log(
        `https://time-masters.mavinx.app/api/search?city=${allFilters.cityToken}` +
          (allFilters.name ? `&search=${allFilters.name.split(" ")[0]}` : ``) +
          (allFilters.priceMin ? `&price_from=${allFilters.priceMin}` : ``) +
          (allFilters.priceMax ? `&price_to=${allFilters.priceMax}` : ``) +
          (allFilters.gender && allFilters.gender != "no_matter"
            ? `&gender=${allFilters.gender}`
            : ``)
      );
      fetch(
        `https://time-masters.mavinx.app/api/search?city=${allFilters.cityToken}` +
          (allFilters.name ? `&search=${allFilters.name.split(" ")[0]}` : ``) +
          (allFilters.priceMin ? `&price_from=${allFilters.priceMin}` : ``) +
          (allFilters.priceMax ? `&price_to=${allFilters.priceMax}` : ``) +
          (allFilters.gender && allFilters.gender != "no_matter"
            ? `&gender=${allFilters.gender}`
            : ``)
      )
        .then((response) => response.json())
        .then((obj) => setPersonsList(obj));
    } else {
      setPersonsList(null);
    }
  };
  // Changing page view to show list
  const changePageView = () => {
    wholePageRef.current.classList.add("changed");
    headingRef.current.innerText = "Пошук майстра";
    subheadingRef.current.style.display = "none";
    continueButtonRef.current.style.display = "none";
    setWithFilters(true);
    setworkByAutocomplete(true);
  };
  // Changing input refreshes list when autocomplete is action button
  useEffect(() => {
    // console.log(allFilters);

    if (workByAutocomplete) {
      refreshList();
    }
  }, [allFilters, workByAutocomplete]);

  // useEffect(() => {
  //   console.log(personsList);
  // }, [personsList]);

  return (
    <div className="search-page" ref={wholePageRef}>
      <img
        className="search-page__upper-decor"
        src={upperDecor}
        alt="upper-decor"
      />
      <div className="search-page__container">
        <h2 className="search-page__heading" ref={headingRef}>
          Вітаємо👋
        </h2>
        <h3 className="search-page__subheading" ref={subheadingRef}>
          Будь ласка, введіть Ваше місто для початку пошуку майстра
        </h3>
        <SearchInput
          className="search-page__search-input"
          withFilters={withFilters}
          forWhat="city"
          setMayContinue={setMayContinue}
          allFilters={allFilters}
          setAllFilters={setAllFilters}
          setShowFiltersWindow={setShowFiltersWindow}
        />
        <button
          className="search-page__button"
          disabled={!mayContinue}
          onClick={changePageView}
          ref={continueButtonRef}
        >
          <span className="search-page__button-text">Далі</span>
          <div className="search-page__button-decor">
            <img src={arrowRight} alt="arrow-right" />
          </div>
        </button>

        <div className="search-page__list" ref={listRef}>
          {personsList?.data?.map((personInfo) => (
            <PersonCard
              personInfo={personInfo}
              cityName={allFilters.cityName}
              key={personInfo.id}
            />
          ))}
        </div>

        {personsList == null || personsList?.data?.length === 0 ? (
          <div className="search-page__not-found">
            <h3 className="search-page__not-found-heading">
              Нічого не знайдено☹️
            </h3>
            <h4 className="search-page__not-found-subheading">
              Спробуйте змінити фільтри та спробувати ще раз.
            </h4>
            <img
              className="search-page__not-found-image"
              src={BigLoupeImage}
              alt="loupe"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <FiltersWindow
        className={"search-page__filters-window"}
        setAllFilters={setAllFilters}
        showFiltersWindow={showFiltersWindow}
        setShowFiltersWindow={setShowFiltersWindow}
        allFilters={allFilters}
      />
    </div>
  );
}

export default SearchPage;
