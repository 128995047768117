import "./style.css";
import { useState, useEffect, useRef } from "react";

function GenderChooseList({ className, allFilters, setAllFilters }) {
  const buttonsRefs = useRef(Array(4));

  const onClickGender = (event) => {
    const value = event.currentTarget.getAttribute("value");
    buttonsRefs.current.forEach((button) => button.classList.remove("active"));
    event.currentTarget.classList.add("active");
    setAllFilters((previousState) => {
      return {
        ...previousState,
        gender: value,
      };
    });
  };

  return (
    <div className={`${className} gender-choose`}>
      <button
        className="gender-choose__item active"
        value="no_matter"
        onClick={onClickGender}
        ref={(element) => (buttonsRefs.current[0] = element)}
      >
        <div className="gender-choose__item-radio">
          <div></div>
        </div>
        <div className="gender-choose__item-text">Неважливо</div>
      </button>

      <button
        className="gender-choose__item"
        value="male"
        onClick={onClickGender}
        ref={(element) => (buttonsRefs.current[1] = element)}
      >
        <div className="gender-choose__item-radio">
          <div></div>
        </div>
        <div className="gender-choose__item-text">Чоловік</div>
      </button>

      <button
        className="gender-choose__item"
        value="female"
        onClick={onClickGender}
        ref={(element) => (buttonsRefs.current[2] = element)}
      >
        <div className="gender-choose__item-radio">
          <div></div>
        </div>
        <div className="gender-choose__item-text">Жінка</div>
      </button>

      <button
        className="gender-choose__item"
        value="other"
        onClick={onClickGender}
        ref={(element) => (buttonsRefs.current[3] = element)}
      >
        <div className="gender-choose__item-radio">
          <div></div>
        </div>
        <div className="gender-choose__item-text">Інше</div>
      </button>
    </div>
  );
}

export default GenderChooseList;
