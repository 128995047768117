import "./style.css";

import upperDecor from "../../assets/decor/upper-decor.png";
import check from "../../assets/images/check.png";

function Error404Page() {
  return (
    <div className="success">
      <img
        className="success__upper-decor"
        src={upperDecor}
        alt="upper-decor"
      />
      <div className="success__container">
        <div className="success__block">
          <h3 className="success__block-heading">Ваш запит відправлено🥳</h3>
          <h4 className="success__block-subheading">
            Майстер зв'яжеться з вами для підтвердження запису.
          </h4>
          <img className="success__block-image" src={check} alt="check" />
        </div>
      </div>
    </div>
  );
}

export default Error404Page;
