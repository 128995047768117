import "./style.css";
import { Link } from "react-router-dom";
import avatarPlaceholderImage from "../../assets/images/avatar-placeholder.png";
import { useState, useEffect } from "react";

function PersonCard({ className, personInfo, cityName }) {
  const [avatar, setAvatar] = useState(avatarPlaceholderImage);

  useEffect(() => {
    if (personInfo?.avatars?.thumb) {
      setAvatar(personInfo.avatars.thumb);
    }
  }, []);

  return (
    <Link className={`person-card`} to={"master-page?id=" + personInfo.id}>
      <div className="person-card__avatar">
        <img src={avatar} alt="person-avatar" />
      </div>
      <div className="person-card__down">
        <div className="person-card__name">{personInfo.name}</div>
        <div className="person-card__surname">{personInfo.surname}</div>
        <div className="person-card__type">{personInfo.type_activity}</div>
        <div className="person-card__place">{cityName}</div>
        <div className="person-card__price">
          Від {personInfo.min_price || 0}₴
        </div>
        {personInfo.stars !== 0 ? (
          <div className="person-card__rating-block">
            <div className="person-card__stars-list">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.07625 0.826875C6.155 0.665 6.32 0.5625 6.5 0.5625C6.68062 0.5625 6.845 0.665 6.92375 0.826875C7.5325 2.07563 8.58 4.22687 8.58 4.22687C8.58 4.22687 10.9619 4.55438 12.3438 4.745C12.5956 4.77938 12.75 4.9925 12.75 5.21187C12.75 5.33063 12.705 5.45125 12.6056 5.54688C11.5994 6.50813 9.86687 8.16625 9.86687 8.16625C9.86687 8.16625 10.29 10.5206 10.535 11.8862C10.5869 12.175 10.3631 12.4375 10.0713 12.4375C9.995 12.4375 9.91875 12.4194 9.84937 12.3819C8.61937 11.7269 6.5 10.6 6.5 10.6C6.5 10.6 4.38062 11.7269 3.15062 12.3819C3.08125 12.4194 3.00437 12.4375 2.92812 12.4375C2.6375 12.4375 2.4125 12.1744 2.465 11.8862C2.71063 10.5206 3.13375 8.16625 3.13375 8.16625C3.13375 8.16625 1.40062 6.50813 0.395 5.54688C0.295 5.45125 0.25 5.33062 0.25 5.2125C0.25 4.9925 0.405625 4.77875 0.656875 4.745C2.03875 4.55438 4.42 4.22687 4.42 4.22687C4.42 4.22687 5.46812 2.07563 6.07625 0.826875Z"
                  fill="#333333"
                />
              </svg>

              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.07625 0.826875C6.155 0.665 6.32 0.5625 6.5 0.5625C6.68062 0.5625 6.845 0.665 6.92375 0.826875C7.5325 2.07563 8.58 4.22687 8.58 4.22687C8.58 4.22687 10.9619 4.55438 12.3438 4.745C12.5956 4.77938 12.75 4.9925 12.75 5.21187C12.75 5.33063 12.705 5.45125 12.6056 5.54688C11.5994 6.50813 9.86687 8.16625 9.86687 8.16625C9.86687 8.16625 10.29 10.5206 10.535 11.8862C10.5869 12.175 10.3631 12.4375 10.0713 12.4375C9.995 12.4375 9.91875 12.4194 9.84937 12.3819C8.61937 11.7269 6.5 10.6 6.5 10.6C6.5 10.6 4.38062 11.7269 3.15062 12.3819C3.08125 12.4194 3.00437 12.4375 2.92812 12.4375C2.6375 12.4375 2.4125 12.1744 2.465 11.8862C2.71063 10.5206 3.13375 8.16625 3.13375 8.16625C3.13375 8.16625 1.40062 6.50813 0.395 5.54688C0.295 5.45125 0.25 5.33062 0.25 5.2125C0.25 4.9925 0.405625 4.77875 0.656875 4.745C2.03875 4.55438 4.42 4.22687 4.42 4.22687C4.42 4.22687 5.46812 2.07563 6.07625 0.826875Z"
                  fill="#333333"
                />
              </svg>

              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.07625 0.826875C6.155 0.665 6.32 0.5625 6.5 0.5625C6.68062 0.5625 6.845 0.665 6.92375 0.826875C7.5325 2.07563 8.58 4.22687 8.58 4.22687C8.58 4.22687 10.9619 4.55438 12.3438 4.745C12.5956 4.77938 12.75 4.9925 12.75 5.21187C12.75 5.33063 12.705 5.45125 12.6056 5.54688C11.5994 6.50813 9.86687 8.16625 9.86687 8.16625C9.86687 8.16625 10.29 10.5206 10.535 11.8862C10.5869 12.175 10.3631 12.4375 10.0713 12.4375C9.995 12.4375 9.91875 12.4194 9.84937 12.3819C8.61937 11.7269 6.5 10.6 6.5 10.6C6.5 10.6 4.38062 11.7269 3.15062 12.3819C3.08125 12.4194 3.00437 12.4375 2.92812 12.4375C2.6375 12.4375 2.4125 12.1744 2.465 11.8862C2.71063 10.5206 3.13375 8.16625 3.13375 8.16625C3.13375 8.16625 1.40062 6.50813 0.395 5.54688C0.295 5.45125 0.25 5.33062 0.25 5.2125C0.25 4.9925 0.405625 4.77875 0.656875 4.745C2.03875 4.55438 4.42 4.22687 4.42 4.22687C4.42 4.22687 5.46812 2.07563 6.07625 0.826875Z"
                  fill="#333333"
                />
              </svg>

              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.07625 0.826875C6.155 0.665 6.32 0.5625 6.5 0.5625C6.68062 0.5625 6.845 0.665 6.92375 0.826875C7.5325 2.07563 8.58 4.22687 8.58 4.22687C8.58 4.22687 10.9619 4.55438 12.3438 4.745C12.5956 4.77938 12.75 4.9925 12.75 5.21187C12.75 5.33063 12.705 5.45125 12.6056 5.54688C11.5994 6.50813 9.86687 8.16625 9.86687 8.16625C9.86687 8.16625 10.29 10.5206 10.535 11.8862C10.5869 12.175 10.3631 12.4375 10.0713 12.4375C9.995 12.4375 9.91875 12.4194 9.84937 12.3819C8.61937 11.7269 6.5 10.6 6.5 10.6C6.5 10.6 4.38062 11.7269 3.15062 12.3819C3.08125 12.4194 3.00437 12.4375 2.92812 12.4375C2.6375 12.4375 2.4125 12.1744 2.465 11.8862C2.71063 10.5206 3.13375 8.16625 3.13375 8.16625C3.13375 8.16625 1.40062 6.50813 0.395 5.54688C0.295 5.45125 0.25 5.33062 0.25 5.2125C0.25 4.9925 0.405625 4.77875 0.656875 4.745C2.03875 4.55438 4.42 4.22687 4.42 4.22687C4.42 4.22687 5.46812 2.07563 6.07625 0.826875Z"
                  fill="#333333"
                />
              </svg>

              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.07625 0.826875C6.155 0.665 6.32 0.5625 6.5 0.5625C6.68062 0.5625 6.845 0.665 6.92375 0.826875C7.5325 2.07563 8.58 4.22687 8.58 4.22687C8.58 4.22687 10.9619 4.55438 12.3438 4.745C12.5956 4.77938 12.75 4.9925 12.75 5.21187C12.75 5.33063 12.705 5.45125 12.6056 5.54688C11.5994 6.50813 9.86687 8.16625 9.86687 8.16625C9.86687 8.16625 10.29 10.5206 10.535 11.8862C10.5869 12.175 10.3631 12.4375 10.0713 12.4375C9.995 12.4375 9.91875 12.4194 9.84937 12.3819C8.61937 11.7269 6.5 10.6 6.5 10.6C6.5 10.6 4.38062 11.7269 3.15062 12.3819C3.08125 12.4194 3.00437 12.4375 2.92812 12.4375C2.6375 12.4375 2.4125 12.1744 2.465 11.8862C2.71063 10.5206 3.13375 8.16625 3.13375 8.16625C3.13375 8.16625 1.40062 6.50813 0.395 5.54688C0.295 5.45125 0.25 5.33062 0.25 5.2125C0.25 4.9925 0.405625 4.77875 0.656875 4.745C2.03875 4.55438 4.42 4.22687 4.42 4.22687C4.42 4.22687 5.46812 2.07563 6.07625 0.826875Z"
                  fill="#333333"
                />
              </svg>

              <div
                className="person-card__stars-over"
                style={{ width: (100 / 5) * personInfo.stars + "%" }}
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.07625 0.826875C6.155 0.665 6.32 0.5625 6.5 0.5625C6.68062 0.5625 6.845 0.665 6.92375 0.826875C7.5325 2.07563 8.58 4.22687 8.58 4.22687C8.58 4.22687 10.9619 4.55438 12.3438 4.745C12.5956 4.77938 12.75 4.9925 12.75 5.21187C12.75 5.33063 12.705 5.45125 12.6056 5.54688C11.5994 6.50813 9.86687 8.16625 9.86687 8.16625C9.86687 8.16625 10.29 10.5206 10.535 11.8862C10.5869 12.175 10.3631 12.4375 10.0713 12.4375C9.995 12.4375 9.91875 12.4194 9.84937 12.3819C8.61937 11.7269 6.5 10.6 6.5 10.6C6.5 10.6 4.38062 11.7269 3.15062 12.3819C3.08125 12.4194 3.00437 12.4375 2.92812 12.4375C2.6375 12.4375 2.4125 12.1744 2.465 11.8862C2.71063 10.5206 3.13375 8.16625 3.13375 8.16625C3.13375 8.16625 1.40062 6.50813 0.395 5.54688C0.295 5.45125 0.25 5.33062 0.25 5.2125C0.25 4.9925 0.405625 4.77875 0.656875 4.745C2.03875 4.55438 4.42 4.22687 4.42 4.22687C4.42 4.22687 5.46812 2.07563 6.07625 0.826875Z"
                    fill="#EECD59"
                  />
                </svg>

                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.07625 0.826875C6.155 0.665 6.32 0.5625 6.5 0.5625C6.68062 0.5625 6.845 0.665 6.92375 0.826875C7.5325 2.07563 8.58 4.22687 8.58 4.22687C8.58 4.22687 10.9619 4.55438 12.3438 4.745C12.5956 4.77938 12.75 4.9925 12.75 5.21187C12.75 5.33063 12.705 5.45125 12.6056 5.54688C11.5994 6.50813 9.86687 8.16625 9.86687 8.16625C9.86687 8.16625 10.29 10.5206 10.535 11.8862C10.5869 12.175 10.3631 12.4375 10.0713 12.4375C9.995 12.4375 9.91875 12.4194 9.84937 12.3819C8.61937 11.7269 6.5 10.6 6.5 10.6C6.5 10.6 4.38062 11.7269 3.15062 12.3819C3.08125 12.4194 3.00437 12.4375 2.92812 12.4375C2.6375 12.4375 2.4125 12.1744 2.465 11.8862C2.71063 10.5206 3.13375 8.16625 3.13375 8.16625C3.13375 8.16625 1.40062 6.50813 0.395 5.54688C0.295 5.45125 0.25 5.33062 0.25 5.2125C0.25 4.9925 0.405625 4.77875 0.656875 4.745C2.03875 4.55438 4.42 4.22687 4.42 4.22687C4.42 4.22687 5.46812 2.07563 6.07625 0.826875Z"
                    fill="#EECD59"
                  />
                </svg>

                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.07625 0.826875C6.155 0.665 6.32 0.5625 6.5 0.5625C6.68062 0.5625 6.845 0.665 6.92375 0.826875C7.5325 2.07563 8.58 4.22687 8.58 4.22687C8.58 4.22687 10.9619 4.55438 12.3438 4.745C12.5956 4.77938 12.75 4.9925 12.75 5.21187C12.75 5.33063 12.705 5.45125 12.6056 5.54688C11.5994 6.50813 9.86687 8.16625 9.86687 8.16625C9.86687 8.16625 10.29 10.5206 10.535 11.8862C10.5869 12.175 10.3631 12.4375 10.0713 12.4375C9.995 12.4375 9.91875 12.4194 9.84937 12.3819C8.61937 11.7269 6.5 10.6 6.5 10.6C6.5 10.6 4.38062 11.7269 3.15062 12.3819C3.08125 12.4194 3.00437 12.4375 2.92812 12.4375C2.6375 12.4375 2.4125 12.1744 2.465 11.8862C2.71063 10.5206 3.13375 8.16625 3.13375 8.16625C3.13375 8.16625 1.40062 6.50813 0.395 5.54688C0.295 5.45125 0.25 5.33062 0.25 5.2125C0.25 4.9925 0.405625 4.77875 0.656875 4.745C2.03875 4.55438 4.42 4.22687 4.42 4.22687C4.42 4.22687 5.46812 2.07563 6.07625 0.826875Z"
                    fill="#EECD59"
                  />
                </svg>

                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.07625 0.826875C6.155 0.665 6.32 0.5625 6.5 0.5625C6.68062 0.5625 6.845 0.665 6.92375 0.826875C7.5325 2.07563 8.58 4.22687 8.58 4.22687C8.58 4.22687 10.9619 4.55438 12.3438 4.745C12.5956 4.77938 12.75 4.9925 12.75 5.21187C12.75 5.33063 12.705 5.45125 12.6056 5.54688C11.5994 6.50813 9.86687 8.16625 9.86687 8.16625C9.86687 8.16625 10.29 10.5206 10.535 11.8862C10.5869 12.175 10.3631 12.4375 10.0713 12.4375C9.995 12.4375 9.91875 12.4194 9.84937 12.3819C8.61937 11.7269 6.5 10.6 6.5 10.6C6.5 10.6 4.38062 11.7269 3.15062 12.3819C3.08125 12.4194 3.00437 12.4375 2.92812 12.4375C2.6375 12.4375 2.4125 12.1744 2.465 11.8862C2.71063 10.5206 3.13375 8.16625 3.13375 8.16625C3.13375 8.16625 1.40062 6.50813 0.395 5.54688C0.295 5.45125 0.25 5.33062 0.25 5.2125C0.25 4.9925 0.405625 4.77875 0.656875 4.745C2.03875 4.55438 4.42 4.22687 4.42 4.22687C4.42 4.22687 5.46812 2.07563 6.07625 0.826875Z"
                    fill="#EECD59"
                  />
                </svg>

                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.07625 0.826875C6.155 0.665 6.32 0.5625 6.5 0.5625C6.68062 0.5625 6.845 0.665 6.92375 0.826875C7.5325 2.07563 8.58 4.22687 8.58 4.22687C8.58 4.22687 10.9619 4.55438 12.3438 4.745C12.5956 4.77938 12.75 4.9925 12.75 5.21187C12.75 5.33063 12.705 5.45125 12.6056 5.54688C11.5994 6.50813 9.86687 8.16625 9.86687 8.16625C9.86687 8.16625 10.29 10.5206 10.535 11.8862C10.5869 12.175 10.3631 12.4375 10.0713 12.4375C9.995 12.4375 9.91875 12.4194 9.84937 12.3819C8.61937 11.7269 6.5 10.6 6.5 10.6C6.5 10.6 4.38062 11.7269 3.15062 12.3819C3.08125 12.4194 3.00437 12.4375 2.92812 12.4375C2.6375 12.4375 2.4125 12.1744 2.465 11.8862C2.71063 10.5206 3.13375 8.16625 3.13375 8.16625C3.13375 8.16625 1.40062 6.50813 0.395 5.54688C0.295 5.45125 0.25 5.33062 0.25 5.2125C0.25 4.9925 0.405625 4.77875 0.656875 4.745C2.03875 4.55438 4.42 4.22687 4.42 4.22687C4.42 4.22687 5.46812 2.07563 6.07625 0.826875Z"
                    fill="#EECD59"
                  />
                </svg>
              </div>
            </div>
            <div className="person-card__rating">{personInfo.stars}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Link>
  );
}

export default PersonCard;
