import "./style.css";
import findGetParameter from "../../utils/findGetParameter";
import arrowRight from "../../assets/icons/arrow-right.png";
import avatarPlaceholderImage from "../../assets/images/avatar-placeholder.png";
import upperDecor from "../../assets/decor/upper-decor.png";
import DropdownMenu from "../../components/DropdownMenu";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

function PersonPage({ outerClass }) {
  const dateInputRef = useRef();

  const [avatar, setAvatar] = useState(avatarPlaceholderImage);
  const [masterInfo, setMasterInfo] = useState({});
  const [masterServices, setMasterServices] = useState([]);
  const [masterFreeTime, setMasterFreeTime] = useState([]);

  const [serviceID, setServiceID] = useState(null);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [seviceText, setServiceText] = useState("Послуга");
  const [timeText, setTimeText] = useState("Час");

  const [showNameError, setShowNameError] = useState(false);
  const [showPhoneError, setshowPhoneError] = useState(false);

  const navigate = useNavigate();

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  useEffect(() => {
    const masterID = findGetParameter("id");
    fetch(`https://time-masters.mavinx.app/api/master/${masterID}`)
      .then((response) => response.json())
      .then((obj) => setMasterInfo(obj))
      .catch(() => (window.location.href = "/error404"));

    fetch(`https://time-masters.mavinx.app/api/master/${masterID}/services`)
      .then((response) => response.json())
      .then((obj) => setMasterServices(obj.data))
      .catch((error) => alert(error));
  }, []);

  useEffect(() => {
    if (masterInfo?.avatars?.thumb) {
      setAvatar(masterInfo.avatars.thumb);
    }
  }, [masterInfo]);

  useEffect(() => {
    setTimeText("Час");
    setTime("");

    if (serviceID && date) {
      fetch(
        `https://time-masters.mavinx.app/api/search/free-time?master_id=${masterInfo.id}&service_id=${serviceID}&date=${date}`,
        {
          method: "POST",
        }
      )
        .then((response) => response.json())
        .then((obj) => setMasterFreeTime(obj))
        .catch((error) => alert(error));
    }
  }, [serviceID, date]);

  // useEffect(() => {
  //   console.log(name);
  //   // console.log(masterFreeTime);
  //   // console.log(date != "" && masterFreeTime?.length == 0);
  // }, [name]);

  function sendData() {
    if (!phone.match(/^(\+380)\s?[0-9]{2}\s?[0-9]{3}\s?[0-9]{4,5}$/)) {
      setshowPhoneError(true);
    } else {
      fetch(
        `https://time-masters.mavinx.app/api/new-entry?service_id=${serviceID}&master_id=${masterInfo.id}&date=${date}&time=${time}&name=${name}&phone=${phone}`,
        {
          method: "POST",
        }
      )
        .then((response) => response.json())
        .then((obj) => obj.message === "success" && navigate("/success"))
        .catch((error) => alert(error));
    }
  }

  return (
    <div className={`${outerClass} master-page`}>
      <img
        className="master-page__upper-decor"
        src={upperDecor}
        alt="upper-decor"
      />
      <div className="master-page__container">
        <div className="master-page__up">
          <img className="master-page__avatar" src={avatar} alt="avatar" />
          <div className="master-page__info">
            <h2 className="master-page__info-header">{`${masterInfo.name} ${masterInfo.surname}`}</h2>

            <h3 className="master-page__info-heading">Номер телефону:</h3>
            <h4 className="master-page__info-subheading">{masterInfo.phone}</h4>

            <h3 className="master-page__info-heading">Місто:</h3>
            <h4 className="master-page__info-subheading">{masterInfo.city}</h4>

            <h3 className="master-page__info-heading">Адреса:</h3>
            <h4 className="master-page__info-subheading">
              {masterInfo.address}
            </h4>
          </div>
        </div>
        <div className="master-page__form">
          <DropdownMenu
            className="master-page__form-input"
            name="service"
            list={masterServices}
            text={seviceText}
            setText={setServiceText}
            setOutput={setServiceID}
            forWhat="service"
          />
          <input
            className={
              "master-page__form-input" +
              (serviceID !== null && date !== "" && masterFreeTime?.length === 0
                ? " error"
                : "")
            }
            type="date"
            name="date"
            min={formatDate(new Date())}
            max={formatDate(
              new Date().setFullYear(new Date().getFullYear() + 1)
            )}
            onInput={(event) => setDate(event.target.value)}
            ref={dateInputRef}
          />
          <div
            className={
              "master-page__input-error" +
              (serviceID !== null && date !== "" && masterFreeTime?.length === 0
                ? " active"
                : "")
            }
          >
            На цю дату немає вільних місць☹️
          </div>
          <DropdownMenu
            className="master-page__form-input"
            name="time"
            list={masterFreeTime}
            text={timeText}
            setText={setTimeText}
            setOutput={setTime}
            forWhat="date"
          />

          <input
            className={
              "master-page__form-input" + (showNameError ? " error" : "")
            }
            type="text"
            name="name"
            minLength={1}
            maxLength={255}
            placeholder="Ваше ім’я"
            onInput={(event) => setName(event.target.value)}
          />
          <div
            className={
              "master-page__input-error" + (showNameError ? " active" : "")
            }
          >
            Ви повинні заповнити своє ім’я
          </div>

          <input
            className={
              "master-page__form-input" + (showPhoneError ? " error" : "")
            }
            type="tel"
            name="phone"
            minLength={10}
            placeholder="Ваш номер телефону"
            defaultValue="+380"
            onInput={(event) => {
              setshowPhoneError(false);

              if (!event.target.value.match(/^(\+380)/)) {
                event.target.value = "+380";
              } else {
                setPhone(event.target.value);
              }
            }}
          />
          <div
            className={
              "master-page__input-error" + (showPhoneError ? " active" : "")
            }
          >
            Ви маєте заповнити український номер телефону
          </div>

          <button
            className="search-page__button"
            disabled={
              serviceID === null ||
              date === "" ||
              time === "" ||
              name === "" ||
              phone === ""
            }
            onClick={sendData}
          >
            <span className="master-page__button-text">Записатися</span>
            <div className="master-page__button-decor">
              <img src={arrowRight} alt="arrow-right" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default PersonPage;
