import "./style.css";
import closeIcon from "../../assets/icons/close.svg";
import arrowRight from "../../assets/icons/arrow-right.png";
import { useState, useEffect, useRef } from "react";
import SearchInput from "../SearchInput";
import PriceRangeInput from "../PriceRangeInput";
import GenderChooseList from "../GenderChooseList";

function FiltersWindow({
  className,
  setAllFilters,
  allFilters,
  showFiltersWindow,
  setShowFiltersWindow,
}) {
  const [isCityChoosen, setIsCityChoosen] = useState(false);
  const [temporaryFilters, setTemporaryFilters] = useState({
    cityName: null,
    cityToken: null,
    name: null,
    priceMin: null,
    priceMax: null,
    gender: null,
  });
  const filtersWindow = useRef();
  // Discards new filters
  const hideFiltersWindow = () => setShowFiltersWindow(false);
  // Applying new filters
  const setNewFilters = () => {
    setShowFiltersWindow(false);
    // setAllFilters(temporaryFilters);
    // setTemporaryFilters({
    //   cityName: null,
    //   cityToken: null,
    //   name: null,
    //   priceMin: null,
    //   priceMax: null,
    //   gender: null,
    // });
  };

  return (
    <div
      className={`${className} filters-window ${
        showFiltersWindow ? "active" : ""
      }`}
      ref={filtersWindow}
    >
      <div className="filters-window__main-container">
        <button
          className="filters-window__close-button"
          onClick={hideFiltersWindow}
        >
          <img src={closeIcon} alt="close-button" />
        </button>
        <div className="filters-window__container">
          <h2 className="filters-window__heading">Фільтри</h2>

          <h3 className="filters-window__filter-heading">Місто*</h3>
          <SearchInput
            className="filters-window__city-input"
            withFilters={false}
            forWhat="city"
            setAllFilters={setAllFilters}
            setMayContinue={setIsCityChoosen}
            allFilters={allFilters}
          />

          <h3 className="filters-window__filter-heading">Майстер</h3>
          <SearchInput
            className="filters-window__city-input"
            withFilters={false}
            forWhat="name"
            setAllFilters={setAllFilters}
          />

          <h3 className="filters-window__filter-heading">Діапазон цін</h3>
          <h3 className="filters-window__price-numbers">{`${allFilters.priceMin}₴ - ${allFilters.priceMax}₴`}</h3>
          <PriceRangeInput
            className={"filters-window__price-range"}
            allFilters={allFilters}
            setAllFilters={setAllFilters}
          />

          <h3
            className="filters-window__filter-heading"
            style={{ marginTop: "40px" }}
          >
            Стать
          </h3>
          <GenderChooseList
            className="filters-window__gender-list"
            setAllFilters={setAllFilters}
          />

          <button
            className="filters-window__button"
            disabled={!isCityChoosen}
            onClick={setNewFilters}
          >
            <span className="filters-window__button-text">
              Показати результати
            </span>
            <div className="filters-window__button-decor">
              <img src={arrowRight} alt="arrow-right" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default FiltersWindow;
