import "./style.css";

function Error404Page() {
  return (
    <div>
      <h1>Error 404</h1>
      <h2>Oops, can't find a page</h2>
    </div>
  );
}

export default Error404Page;
