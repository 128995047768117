import "./style.css";
import loupeIcon from "../../assets/icons/loupe.png";
import filtersIcon from "../../assets/icons/filter.png";
import { useRef, useState, useEffect } from "react";

function SearchInput({
  className,
  withFilters,
  forWhat,
  setMayContinue,
  allFilters,
  setAllFilters,
  setShowFiltersWindow,
}) {
  const [hasEquation, setHasEquation] = useState(false);
  const [currentInputValue, setCurrentInputValue] = useState("");
  const [possibleOptions, setpossibleOptions] = useState([]);
  const [possibleOptionsElements, setPossibleOptionsElement] = useState([]);
  const inputElement = useRef();
  // const

  useEffect(() => {
    // Fetching for autocomplete options for differend types of inputs
    if (currentInputValue !== "" && currentInputValue !== " ") {
      switch (forWhat) {
        case "city":
          fetch(
            "https://time-masters.mavinx.app/api/autocomplete?search=" +
              currentInputValue
          )
            .then((response) => response.json())
            .then((obj) => setpossibleOptions(obj));
          break;
        case "name":
          fetch(
            "https://time-masters.mavinx.app/api/search?search=" +
              currentInputValue
          )
            .then((response) => response.json())
            .then((obj) => setpossibleOptions(obj.data));
          break;
        default:
          break;
      }

      setHasEquation(true);
    } else {
      setHasEquation(false);
    }
    // Makes autocomplete options list for differend types of inputs
    switch (forWhat) {
      case "city":
        setPossibleOptionsElement(
          possibleOptions.map((option, index) => {
            return (
              <button
                className="search-container__dropdown-item"
                key={index}
                onClick={(event) => {
                  inputElement.current.value = event.target.innerText;
                  inputElement.current.setAttribute(
                    "place_id",
                    option["place_id"]
                  );
                  setHasEquation(false);
                  setMayContinue && setMayContinue(true);
                  setAllFilters((previousFiltersState) => ({
                    ...previousFiltersState,
                    cityName: event.target.innerText,
                    cityToken: option["place_id"],
                  }));
                }}
              >
                {option["main_text"]}
              </button>
            );
          })
        );
        break;
      case "name":
        setPossibleOptionsElement(
          possibleOptions.map((option, index) => {
            return (
              <button
                className="search-container__dropdown-item"
                key={index}
                onClick={(event) => {
                  inputElement.current.value = event.target.innerText;
                  setHasEquation(false);
                  // setMayContinue(true);
                  setAllFilters((previousFiltersState) => ({
                    ...previousFiltersState,
                    name: event.target.innerText,
                  }));
                }}
              >
                {`${option["name"]} ${option["surname"]}`}
              </button>
            );
          })
        );
        break;
      default:
        break;
    }
  }, [currentInputValue]);

  useEffect(() => {
    console.log(allFilters);
    // If city was already assigned, then show it
    if (allFilters?.cityName) {
      inputElement.current.value = allFilters.cityName;
      // inputElement.current.setAttribute("place_id", allFilters.cityToken);
      setMayContinue(true);
    }
    // else if (allFilters?.cityName === null) {
    //   inputElement.current.value = null;
    // }
  }, [, allFilters]);

  useEffect(() => {
    // if (allFilters?.cityName) {
    //   inputElement.current.value = allFilters.cityName;
    //   inputElement.current.setAttribute("place_id", allFilters.cityToken);
    //   setMayContinue(true);
    // }
    // // If city was already assigned, then allowing to continue
    // if (setMayContinue && inputElement.current.value) {
    //   switch (forWhat) {
    //     case "city":
    //       setAllFilters((previousFiltersState) => ({
    //         ...previousFiltersState,
    //         cityName: inputElement.current.value,
    //         cityToken: inputElement.current.getAttribute("place_id"),
    //       }));
    //       break;
    //     default:
    //       break;
    //   }
    // }

    if (withFilters) {
      inputElement.current.classList.add("shrink");
    }
  }, [withFilters]);

  function getGenderName(genderType) {
    switch (genderType) {
      case "no_matter":
        return "Неважливо";
      case "male":
        return "Чоловік";
      case "female":
        return "Жінка";
      case "other":
        return "Інше";
      default:
        return "Неважливо";
    }
  }

  return (
    <div className={`${className} search-container`}>
      <div className="search-container__main">
        <div className="search-container__main-left">
          <input
            className="search-container__input"
            type="text"
            minLength={2}
            maxLength={25}
            placeholder={forWhat == "city" ? "Пошук міста" : "Послуга або ім’я"}
            onInput={(event) => {
              setCurrentInputValue(event.target.value);
              switch (forWhat) {
                case "city":
                  setAllFilters((previousFiltersState) => ({
                    ...previousFiltersState,
                    cityName: null,
                    cityToken: null,
                  }));
                  break;
                case "name":
                  setAllFilters((previousFiltersState) => ({
                    ...previousFiltersState,
                    name: null,
                  }));
                  break;
                default:
                  break;
              }
              setMayContinue && setMayContinue(false);
            }}
            ref={inputElement}
          />
          <div className="search-container__filter-output">
            {withFilters
              ? `
              ${allFilters?.name ? allFilters.name : ""}
              ${allFilters?.priceMin ? " · " + allFilters.priceMin : ""}
              ${allFilters?.priceMax ? " - " + allFilters.priceMax + "₴" : ""}
              ${
                allFilters?.gender
                  ? " · " + getGenderName(allFilters.gender)
                  : ""
              }`
              : ""}
          </div>
        </div>
        <div className="search-container__main-right">
          <button className="search-container__search-button">
            <img src={loupeIcon} alt="search" />
          </button>

          {withFilters && (
            <button
              className="search-container__filters-button"
              onClick={() => setShowFiltersWindow(true)}
            >
              <img src={filtersIcon} alt="filter" />
            </button>
          )}
        </div>
      </div>
      {hasEquation && (
        <div className="search-container__dropdown-list">
          {possibleOptionsElements}
        </div>
      )}
    </div>
  );
}

export default SearchInput;
